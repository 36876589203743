import { useEffect } from "react";

const WebMail = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.replace("https://webmail.medart-technology.info");
    }
  });
  return null;
};

export default WebMail;
